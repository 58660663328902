import { BPM_MAX, BPM_MIN, YEAR_MIN } from 'app/constants';
import {
  DATE_RANGE,
  SLIDER_TYPE,
  SORT_TYPE,
  TYPE_FILTER,
} from 'app/constants/enum';
import { selectAuth } from 'app/pages/Login/slice/selectors';
import { useCratesSlice } from 'app/pages/PageCrate/slice';
import { useTrackSlice } from 'app/pages/Tracks/slice';
import { selectSliceTracks } from 'app/pages/Tracks/slice/selectors';
import axiosService from 'app/services/axios.service';
import { convertBase64toFile } from 'app/utils/convertBase64ToFile';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getPaginationParams } from 'utils/pagination';
import { useCommunity } from '../Community/useCommunity';
import { useSections } from '../sections/useSections';
import { hideAndHiddenMyTrack } from 'app/apis/track/track';
import { useDisclosure } from '@chakra-ui/react';
import { useResultsPerPage } from '../resultsPerPage/useResultsPerPage';

export const useTracks = () => {
  const { perPageLocalStorage } = useResultsPerPage();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { actions } = useTrackSlice();
  const { actions: actionsCrates } = useCratesSlice();
  const { userDetail } = useSelector(selectAuth);
  const [showInputTittlePlaylist, setShowInputTittlePlaylist] = useState(false);
  const [showCrate, setShowCrate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowAllTracks, setShowAllTracks] = useState(false);
  const [isShowFilterTrack, setIsShowFilterTrack] = useState(false);
  const [selectedTime, setSelectedTime] = useState<any>(DATE_RANGE.LAST_7_DAYS);
  const [arraySearchGenres, setArraySearchGenres] = useState([]);
  const [arraySearchSubGenres, setArraySearchSubGenres] = useState([]);
  const { sections } = useSections();
  const { contributors } = useCommunity();
  const query = queryString.parse(search);
  const [range, setRange] = useState({
    bpmStart: 0,
    bpmEnd: 220,
    yearFrom: 1950,
    yearTo: new Date().getFullYear(),
  });
  const [isHideAndHiddenMyTrack, setIsHideAndHiddenMyTrack] = useState(false);

  const {
    isOpen: isShowModalConfirmHideTrack,
    onOpen: onOpenModalConfirmHideTrack,
    onClose: onCloseModalConfirmHideTrack,
  } = useDisclosure();

  const [arraySearchTags, setArraySearchTags] = useState([]);
  const itemsRef = useRef<any>([]);

  const {
    tracks,
    isLoading,
    pagination,
    featuredTracks,
    isLoadingFeaturedTrack,
    tracksByCreateAt,
    isLoadingCreateAt,
    top10Tracks,
    isLoadingTop10Track,
    listTracks,
    myTracks,
    totalPage,
    currentPage,
    topTracks,
    listTracksUploadedSuccess,
    isDownloadSuccess,
    trackIdDownloaded,
    isLoadingMore,
    isShowFilter,
    tokenMax,
    tokenMin,
    isCheckOpenModelBuySubscription,
    isUpdatingTrack,
    isUpdatingTrackSuccess,
    showFavoriteByMe,
  } = useSelector(selectSliceTracks);

  const timeoutRef = useRef<any>(null);
  const loaderMoreRef = useRef<any>(null);
  const location: any = useLocation();
  const [searchValue, setSearchValue] = useState<string>('');
  const isTrackDetailPage = pathname.includes('/tracks/');
  const isTracksPage = pathname.includes('/tracks');
  const isHomePage = pathname.includes('/home');

  const isLabelDetail =
    pathname.includes('/labels/') &&
    (query?.tab === '0' || query?.tab === undefined);

  const isReleaseDetail = pathname.includes('/multipacks/');

  const isContributorDetail =
    pathname.includes('/contributors/') && query?.tab === '0';

  const isMyMedia = pathname.includes('/my-media');

  const isNoOpenFilterTrack = useMemo(() => {
    const tab: any = query.tab || 0;
    if (pathname.includes('/contributors/') && [1, 2, 3].includes(+tab)) {
      return true;
    }
    if (pathname.includes('/labels/') && [1, 2].includes(+tab)) {
      return true;
    }
    if (pathname.includes('/playlists/')) {
      return true;
    }
    if (pathname.includes('/multipacks/')) {
      return true;
    }
    if (pathname.includes('/my-media')) {
      return true;
    }
    return false;
  }, [pathname, query?.tab]);

  const onToggleCrate = useCallback(() => {
    return setShowCrate(prev => !prev);
  }, []);

  const handleShowInput = useCallback(() => {
    setShowInputTittlePlaylist(pre => !pre);
  }, []);

  // const handleShowTags = useCallback((params, key) => {
  //   return typeof params[key] === 'string'
  //     ? [{ value: params.showTags }]
  //     : (params[key] || []).map(item => {
  //         return { value: item };
  //       });
  // }, []);

  const handleUserSlug = useCallback(() => {
    if (pathname.includes('contributors')) {
      const [, , slug] = pathname?.split('/');
      return slug;
    }
    if (search.includes('tab=2')) return '';
    if (
      pathname.includes('my-media') ||
      search.includes('tag=0') ||
      search.includes('tag=1')
    )
      return userDetail?.slug;
  }, [pathname, search, userDetail?.slug]);

  const handleLabelId = useCallback(() => {
    if (pathname.includes('labels')) {
      const [, , labelId] = pathname?.split('/');
      return labelId;
    }
    return '';
  }, [pathname]);

  const handleReleaseSlug = useCallback(() => {
    if (pathname.includes('multipacks')) {
      const [, , releaseSlug] = pathname?.split('/');
      return releaseSlug;
    }
    return '';
  }, [pathname]);

  const handleGenId = useCallback(() => {
    if (location.pathname.includes('genres')) {
      return location.search.split('id=')[1];
    }
    return '';
  }, [location.pathname, location.search]);

  const DEFAULT_FILTERS = useMemo(() => {
    return {
      showAudio: true,
      showVideo: true,
      clean: true,
      dirty: true,
      bpmStart: 0,
      bpmEnd: 220,
      yearFrom: 1950,
      yearTo: new Date().getFullYear(),
      sort:
        isHomePage || isLabelDetail || isContributorDetail
          ? SORT_TYPE.TOP_MOVERS_30
          : isReleaseDetail
          ? SORT_TYPE.INDEX_ASC
          : SORT_TYPE.PUBLISHED_AT_DESC,
      userSlug: handleUserSlug(),
      labelId: handleLabelId(),
      releaseSlug: handleReleaseSlug(),
      search: '',
      page: 1,
      pageSize:
        isHomePage || isLabelDetail || isContributorDetail
          ? 20
          : perPageLocalStorage,
      genreId: handleGenId(),
      isMyMedia,
    };
  }, [
    isHomePage,
    isLabelDetail,
    isContributorDetail,
    isReleaseDetail,
    handleUserSlug,
    handleLabelId,
    handleReleaseSlug,
    perPageLocalStorage,
    handleGenId,
    isMyMedia,
  ]);

  const initialFilter: any = useMemo(() => {
    return {
      showAudio: true,
      showVideo: true,
      clean: true,
      dirty: true,
      bpmStart: 0,
      bpmEnd: 220,
      yearFrom: 1950,
      yearTo: new Date().getFullYear(),
      sort:
        isHomePage || isLabelDetail || isContributorDetail
          ? SORT_TYPE.TOP_MOVERS_7
          : isReleaseDetail
          ? SORT_TYPE.INDEX_ASC
          : SORT_TYPE.PUBLISHED_AT_DESC,
      userSlug: handleUserSlug(),
      labelId: handleLabelId(),
      releaseSlug: handleReleaseSlug(),
      search: '',
      page: 1,
      pageSize:
        isHomePage || isLabelDetail || isContributorDetail
          ? 20
          : perPageLocalStorage,
      genreId: handleGenId(),
      showGenres: [],
      showTags: [],
      showTrackKeys: [],
      showContributors: [],
    };
  }, [
    perPageLocalStorage,
    handleGenId,
    handleLabelId,
    handleReleaseSlug,
    handleUserSlug,
    isContributorDetail,
    isHomePage,
    isLabelDetail,
    isReleaseDetail,
  ]);

  const [filter, setFilter] = useState<any>(DEFAULT_FILTERS);

  useEffect(() => {
    if (filter.showFavoriteByMe !== undefined)
      dispatch(actions.updateShowFavoriteByMe(filter.showFavoriteByMe));
  }, [actions, dispatch, filter.showFavoriteByMe]);

  useEffect(() => {
    if (
      filter?.bpmStart ||
      filter?.bpmEnd ||
      filter?.yearFrom ||
      filter?.yearTo
    ) {
      setRange({
        bpmStart: filter?.bpmStart,
        bpmEnd: filter?.bpmEnd,
        yearFrom: filter?.yearFrom,
        yearTo: filter?.yearTo,
      });
    }
  }, [filter]);

  const TRACK_BASE_URL = '/v1/tracks';

  const onGetSearchTrack = useCallback(
    async (searchValue: string) => {
      const filterSearch = {
        ...filter,
        search: searchValue,
      };

      const page = filterSearch.page || 1;

      const pageSize = filterSearch?.pageSize || perPageLocalStorage;
      const paramsPagination = getPaginationParams({ page, pageSize });

      const response = await axiosService.post(
        `${TRACK_BASE_URL}/search?${paramsPagination}`,
        filterSearch,
      );
      return response?.data.data;
    },
    [perPageLocalStorage, filter],
  );

  const onGetTracks = useCallback(async () => {
    const page = filter.page || 1;
    const pageSize = filter?.pageSize || perPageLocalStorage;
    const paramsPagination = getPaginationParams({ page, pageSize });
    dispatch(
      actions.getTracksRequest({
        params: paramsPagination,
        filter: {
          ...filter,
          sort: filter.sort,
          showSections: filter?.showSections?.map(item => item?.value),
          showTrackKeys: filter?.showTrackKeys?.map(item => item?.value),
          showContributors: filter?.showContributors?.map(item => item?.value),
          showTags: filter?.showTags?.map(item => item?.value),
          showGenres: filter?.showGenres?.map(item => item?.value),
          showSubGenres: filter?.showSubGenres?.map(item => item?.value),
          labelId: filter.labelId || handleLabelId(),
          releaseSlug: handleReleaseSlug(),
          // clean: isEmpty(getLocalStorage('clean')) || getLocalStorage('clean'),
          // dirty: isEmpty(getLocalStorage('dirty')) || getLocalStorage('dirty'),
        },
      }),
    );
  }, [
    perPageLocalStorage,
    actions,
    dispatch,
    filter,
    handleLabelId,
    handleReleaseSlug,
  ]);

  const updateTrack = useCallback(
    (trackId, dataUpdate) => {
      const data = {
        trackId,
        dataUpdate,
      };
      dispatch(actions.updateTrackRequest(data));
    },
    [actions, dispatch],
  );

  const onDeleteTrack = useCallback(
    (trackId: string) => {
      dispatch(actions.deleteTracksRequest({ trackId }));
    },
    [actions, dispatch],
  );

  const onClear = useCallback(
    (labelId?: string) => {
      dispatch(actionsCrates.removeRule({}));
      labelId
        ? setFilter({
            ...DEFAULT_FILTERS,
            labelId,
          })
        : setFilter(DEFAULT_FILTERS);
    },
    [DEFAULT_FILTERS, actionsCrates, dispatch],
  );

  const handleChange = useCallback(
    e => {
      setSearchValue(e.target.value);
      if (timeoutRef) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setFilter(current => {
          return {
            ...current,
            search: e.target.value,
            page: 1,
          };
        });
        if (
          (!isTracksPage || isTrackDetailPage) &&
          !isNoOpenFilterTrack &&
          !pathname.includes('/labels') &&
          !pathname.includes('/contributors')
        ) {
          history.push({
            pathname: `/tracks`,
            search: `?isFilter=true`,
            state: {
              search: e.target.value,
            },
          });
        }
      }, 1500);
    },

    [history, isNoOpenFilterTrack, isTrackDetailPage, isTracksPage, pathname],
  );

  const handleChangeFilter = useCallback(
    (event, key, type: any) => {
      key === 'showGenres' && setArraySearchGenres(event);
      key === 'showSubGenres' && setArraySearchSubGenres(event);

      key === 'showTags' && setArraySearchTags(event);
      setFilter(current => ({
        ...current,
        page: 1,
      }));
      switch (type) {
        case SLIDER_TYPE.DROPDOWN:
          setFilter(current => ({
            ...current,
            [key]: event,
          }));
          break;
        case TYPE_FILTER.TYPE_QUERY_FILTER:
          setFilter(current => ({
            ...current,
            [type]: event ? TYPE_FILTER.ALL : TYPE_FILTER.EACH,
          }));
          break;
        case SLIDER_TYPE.BPM:
          setFilter(current => ({
            ...current,
            bpmFrom: BPM_MIN,
            bpmTo: BPM_MAX,
          }));
          break;
        case SLIDER_TYPE.PRICE:
          setFilter(current => ({
            ...current,
            priceFrom: tokenMin,
            priceTo: tokenMax,
          }));
          break;
        case SLIDER_TYPE.YEAR:
          setFilter(current => ({
            ...current,
            yearFrom: YEAR_MIN,
            yearTo: new Date().getFullYear(),
          }));
          break;
        default:
          const { name } = event.target;
          setFilter(current => ({
            ...current,
            [name]: !filter[name],
          }));
          break;
      }

      if (
        (!isTracksPage || isTrackDetailPage) &&
        !isNoOpenFilterTrack &&
        !pathname.includes('/labels') &&
        !pathname.includes('/contributors')
      ) {
        setTimeout(() => {
          history.push({
            pathname: `/tracks`,
            search: '?isFilter=true',
          });
        }, 100);
      }
    },
    [
      isTracksPage,
      isTrackDetailPage,
      isNoOpenFilterTrack,
      pathname,
      tokenMin,
      tokenMax,
      filter,
      history,
    ],
  );

  useEffect(() => {
    if ((!isTracksPage || isTrackDetailPage) && !isNoOpenFilterTrack) {
      dispatch(actions.addFilterTrack(filter));
    }
  }, [
    actions,
    dispatch,
    filter,
    isNoOpenFilterTrack,
    isTrackDetailPage,
    isTracksPage,
  ]);

  const handleRangeSlider = useCallback(
    (value, key) => {
      if (timeoutRef) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setFilter(current => ({
          ...current,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
          page: 1,
        }));
      }, 1000);
      if (
        (!isTracksPage || isTrackDetailPage) &&
        !isNoOpenFilterTrack &&
        !pathname.includes('/contributors') &&
        !pathname.includes('/labels')
      ) {
        setTimeout(() => {
          history.push({
            pathname: `/tracks`,
            search: '?isFilter=true',
          });
        }, 1100);
      }
    },
    [history, isNoOpenFilterTrack, isTrackDetailPage, isTracksPage, pathname],
  );

  const handleFilterBpmOrYear = useCallback(
    (key, value) => {
      setRange({
        ...range,
        [key]: value,
      });
      if (timeoutRef) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setFilter(current => ({
          ...current,
          [key]: value,
          page: 1,
        }));
      }, 500);
    },
    [range],
  );

  const handleFilterCheckbox = useCallback((key, value) => {
    setFilter(current => ({
      ...current,
      [key]: value,
      page: 1,
    }));
  }, []);

  const fetchMoreData = useCallback(() => {
    setFilter(current => {
      return {
        ...current,
        page: currentPage + 1,
      };
    });
  }, [currentPage]);

  const handleObserverLoadMoreTrack = useCallback(
    entities => {
      const target = entities[0];
      if (
        target.isIntersecting &&
        totalPage > currentPage &&
        currentPage !== 0
      ) {
        fetchMoreData();
      }
    },
    [currentPage, fetchMoreData, totalPage],
  );

  const [listId, setListId] = useState<string[]>([]);
  const handleShowFullTrack = (ids: string[] = [], type?: string) => {
    if (type === 'single') {
      const found = ids.some(r => listId.includes(r));
      const d = ids.join('');
      const e = listId.filter(item => item !== d);
      return found ? setListId(e) : setListId([...listId, ...ids]);
    } else {
      setListId(ids);
    }
  };

  const handleArtworkTrack = useCallback(async (artwork, title) => {
    let file;
    if (!artwork?.dataAsTagSrc) {
      file = artwork;
    } else {
      const [, type] = artwork?.format.split('/');
      file = convertBase64toFile(artwork?.dataAsTagSrc, `${title}.${type}`);
    }
    return file;
  }, []);

  const onHandleClickItemTagGenre = useCallback(
    (type, data) => {
      history.push({
        pathname: `/tracks`,
        state: {
          type,
          value: data?._id,
          label: data?.name,
        },
      });
    },
    [history],
  );

  const handleShowAllTrack = useCallback(
    itemsRef => {
      setShowAllTracks(pre => !pre);
      if (itemsRef?.current.length) {
        itemsRef.current.map(ref =>
          isShowAllTracks
            ? ref.onHandleToggleClose()
            : ref.onHandleToggleOpen(),
        );
      }
    },
    [isShowAllTracks, setShowAllTracks],
  );

  const toggleShowFilter = useCallback(() => {
    dispatch(actions.toggleShowFilter());
  }, [actions, dispatch]);

  const setIsUpdatingTrack = useCallback(
    isUpdatingTrack => {
      dispatch(
        actions.setIsUpdatingTrack({
          isUpdatingTrack,
        }),
      );
    },
    [actions, dispatch],
  );

  const setIsUpdatingTrackSuccess = useCallback(
    isUpdatingTrackSuccess => {
      dispatch(
        actions.setIsUpdatingTrackSuccess({
          isUpdatingTrackSuccess,
        }),
      );
    },
    [actions, dispatch],
  );

  const removeToggleShowFilter = useCallback(() => {
    dispatch(actions.removeToggleShowFilter());
  }, [actions, dispatch]);

  const setShowFilter = useCallback(() => {
    dispatch(actions.setShowFilter());
  }, [actions, dispatch]);

  const getTokenMax = useCallback(() => {
    dispatch(actions.getTokenMaxRequest());
  }, [actions, dispatch]);

  const handleHideAndHiddenMyTrack = useCallback(
    async (track: any) => {
      setIsHideAndHiddenMyTrack(true);
      const data: any = await hideAndHiddenMyTrack({ track });

      if (data) dispatch(actions.updateTextHideAndHiddenMyTrack({ data }));

      setIsHideAndHiddenMyTrack(false);
      onCloseModalConfirmHideTrack();
    },
    [actions, dispatch, onCloseModalConfirmHideTrack],
  );

  const addRemoveFavoriteTrack = useCallback(
    trackId => {
      dispatch(actions.updateFavoritedTrackRequest({ trackId }));
    },
    [actions, dispatch],
  );

  useEffect(() => {
    if (location.state?.showFavoriteByMe !== undefined) {
      setShowFilter();
      setIsShowFilterTrack(true);
      setFilter(current => ({
        ...current,
        showFavoriteByMe: Boolean(location.state?.showFavoriteByMe),
      }));
      history.replace({
        ...location,
        state: undefined,
      });
    }
  }, [
    history,
    location,
    location.state,
    setFilter,
    setIsShowFilterTrack,
    setShowFilter,
  ]);

  useEffect(() => {
    if (showFavoriteByMe && !isMyMedia) {
      setFilter(current => ({
        ...current,
        showFavoriteByMe: showFavoriteByMe,
      }));
    }
  }, [isMyMedia, showFavoriteByMe]);

  return {
    handleShowAllTrack,
    onGetTracks,
    tracks,
    isLoading,
    pagination,
    filter,
    showInputTittlePlaylist,
    handleChange,
    handleChangeFilter,
    handleShowInput,
    setFilter,
    onToggleCrate,
    showCrate,
    featuredTracks,
    isLoadingFeaturedTrack,
    tracksByCreateAt,
    isLoadingCreateAt,
    onClear,
    top10Tracks,
    isLoadingTop10Track,
    listTracks,
    myTracks,
    fetchMoreData,
    currentPage,
    handleShowFullTrack,
    listId,
    setListId,
    handleRangeSlider,
    isOpen,
    onGetSearchTrack,
    setIsOpen,
    DEFAULT_FILTERS,
    initialFilter,
    setSelectedTime,
    selectedTime,
    topTracks,
    isShowAllTracks,
    setShowAllTracks,
    onDeleteTrack,
    updateTrack,
    handleArtworkTrack,
    listTracksUploadedSuccess,
    isDownloadSuccess,
    trackIdDownloaded,
    isLoadingMore,
    handleObserverLoadMoreTrack,
    loaderMoreRef,
    onHandleClickItemTagGenre,
    itemsRef,
    toggleShowFilter,
    isShowFilter,
    removeToggleShowFilter,
    setShowFilter,
    getTokenMax,
    tokenMax,
    tokenMin,
    searchValue,
    setSearchValue,
    arraySearchGenres,
    arraySearchSubGenres,
    arraySearchTags,
    isNoOpenFilterTrack,
    isCheckOpenModelBuySubscription,
    sections,
    contributors,
    isShowFilterTrack,
    setIsShowFilterTrack,
    totalPage,
    isUpdatingTrack,
    setIsUpdatingTrack,
    isUpdatingTrackSuccess,
    setIsUpdatingTrackSuccess,
    handleFilterBpmOrYear,
    handleFilterCheckbox,
    range,
    handleHideAndHiddenMyTrack,
    isHideAndHiddenMyTrack,
    isShowModalConfirmHideTrack,
    onOpenModalConfirmHideTrack,
    onCloseModalConfirmHideTrack,
    addRemoveFavoriteTrack,
  };
};
