import { socket } from 'app/contexts/WebsocketContext';
import { toastError, toastSuccess } from 'app/helpers/toast';
import { useTrackSlice } from 'app/pages/Tracks/slice';
import { AUDIO, MP3_FILE, MP4_FILE } from 'app/constants';
import { getLocalStorage, removeLocalStorage } from 'app/helpers/local-storage';
import { useSubscriptionsSlice } from 'app/pages/Subscriptions/slice';
import { actionsTrackDetail } from 'app/pages/TracksDetail/slice';
import { actionsWishlists } from 'app/pages/Wishlist/slice';
import axios from 'axios';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSlack } from 'app/apis/track';
import { selectAuth } from 'app/pages/Login/slice/selectors';
import { SLACK_CHANNELS } from 'app/constants/enum';
import { convertObjectToArray } from 'utils/shared';

export const useSocket = () => {
  const dispatch = useDispatch();
  const { actions: actionsSubscriptions } = useSubscriptionsSlice();
  const { actions: actionsTrack } = useTrackSlice();
  const { userDetail } = useSelector(selectAuth);

  const options = useMemo(() => {
    return {
      success: async () => {
        const trackId = getLocalStorage('trackId');
        dispatch(
          actionsTrack.updateNumberDownloadTrackRequest({
            trackId,
          }),
        );
        toastSuccess('Success! Files saved to your Dropbox.');
        sendSlack({
          text: '✅ Track save to Dropbox succeses',
          block: '✅ Track save to Dropbox succeses',
          channelId: SLACK_CHANNELS.DOWNLOADS,
          attachments: [`Track ID: ${trackId}`, `User ID: ${userDetail?._id}`],
        });
        removeLocalStorage('trackId');
        dispatch(
          actionsTrack.updateIsSavingDropboxTrack({
            isSavingDropboxTrack: false,
          }),
        );
      },
      process: function () {},
      cancel: function () {
        removeLocalStorage('trackId');
        dispatch(
          actionsTrack.updateIsSavingDropboxTrack({
            isSavingDropboxTrack: false,
          }),
        );
      },
      error: function (error) {
        toastError(
          "Please slow down if you're downloading multiple tracks too fast. Feel free to submit a ticket at Contact Us page for any query. Thank you!",
        );
        const text = `❗️ Track save to Dropbox failed`;
        const block = `❗️ Track save to Dropbox failed\n\`\`\`${JSON.stringify(
          error,
          null,
          2,
        )}\`\`\``;

        sendSlack({
          text,
          block,
          channelId: SLACK_CHANNELS.ERRORS,
          attachments: [
            `Track ID: ${getLocalStorage('trackId')}`,
            `User ID: ${userDetail?._id},`,
          ],
        });
        removeLocalStorage('trackId');
        dispatch(
          actionsTrack.updateIsSavingDropboxTrack({
            isSavingDropboxTrack: false,
          }),
        );
      },
    };
  }, [actionsTrack, dispatch, userDetail?._id]);

  useEffect(() => {
    socket.on('getPreSignUrlTrack', async data => {
      if (data) {
        if (data?.typeDownload === 'dropbox') {
          const pathName = `${data?.title}.${
            data?.type === AUDIO ? MP3_FILE : MP4_FILE
          }`;
          const button = (window as any)?.Dropbox.save(
            data?.preSignUrl,
            pathName,
            options,
          );
          (window as any)?.document
            ?.getElementById('root')
            ?.appendChild(button);
        } else {
          axios
            .get(data?.preSignUrl, { responseType: 'arraybuffer' })
            .then(async response => {
              // const url = window.URL.createObjectURL(new Blob([response.data]));
              // const link = document.createElement('a');
              // link.href = url;
              // link.setAttribute(
              //   'download',
              //   `${data?.title} - ${data?.artist}.${
              //     data?.type === AUDIO ? MP3_FILE : MP4_FILE
              //   }`,
              // );
              // document.body.appendChild(link);
              // link.click();
              // link.remove();
              const blob = new Blob([response.data], {
                type: response.headers['content-type'],
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `${data?.title} - ${data?.artist}.${
                  data?.type === AUDIO ? MP3_FILE : MP4_FILE
                }`,
              );
              document.body.appendChild(link);
              link.click();

              // Xóa thẻ <a> sau khi hoàn thành
              link.remove();
              if (data?.isUpdateNumberDownload) {
                dispatch(
                  actionsTrack.updateNumberDownloadTrackRequest({
                    trackId: data?.trackId,
                  }),
                );
              }

              if (data?.isBuyTrackWithSubscription) {
                dispatch(
                  actionsSubscriptions.updateSubscriptionsAfterBuyTrack(),
                );
                dispatch(
                  actionsWishlists.updateTrackandReleaseinWishlistsInReducer([
                    {
                      _id: data?.trackId,
                    },
                  ]),
                );
                dispatch(
                  actionsTrack.buyTrackBySubscriptionSuccess({
                    trackId: data?.trackId,
                  }),
                );
                dispatch(
                  actionsTrackDetail.buyTrackBySubscriptionSuccess({
                    trackId: data?.trackId,
                  }),
                );
                if (data?.isBuyTopTrackWithSubscription) {
                  dispatch(actionsTrack.updateIsDownloadTrackSuccess(true));
                }
                dispatch(actionsTrack.updateTrackBySubscriptionSuccess(false));
                dispatch(actionsTrack.buyTrackIdBySub(null));
                toastSuccess('Download success!');
              }
              sendSlack({
                text: '✅ Track file download succeses',
                block: '✅ Track file download succeses',
                channelId: SLACK_CHANNELS.DOWNLOADS,
                attachments: convertObjectToArray({
                  ...data,
                  userId: userDetail?._id,
                }),
              });
            })
            .catch((error: any) => {
              toastError(
                "Please slow down if you're downloading multiple tracks too fast. Feel free to submit a ticket at Contact Us page for any query. Thank you!",
              );
              const text = `❗️ Track file download failed`;
              const block = `❗️ Track file download failed\n\`\`\`${JSON.stringify(
                error,
                null,
                2,
              )}\`\`\``;
              sendSlack({
                text,
                block,
                channelId: SLACK_CHANNELS.ERRORS,
                attachments: convertObjectToArray({
                  ...data,
                  userId: userDetail?._id,
                }),
              });
            })
            .finally(() => {
              dispatch(
                actionsTrack.updateIsDownloadingTrack({
                  isDownloadingTrack: false,
                }),
              );
            });
        }
      }
    });
    return () => {
      socket.off('getPreSignUrlTrack');
    };
  }, [actionsSubscriptions, actionsTrack, dispatch, options, userDetail]);
};
